import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/staking.json"

import { ChevronDownIcon } from "@chakra-ui/icons";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = useMemo(() => new ethers.Contract('0x4b4DB680643d6124794CE1D39Ea9db6113919Ff6', abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [nfts, setNfts] = useState([])
    const [stakedBBB, setStakedBBB] = useState()
    const [stakedGB, setStakedGB] = useState()
    const [currentReward, setCurrentReward] = useState({ bizreward: 0, gigareward: 0 })
    const [ownedBBB, setOwnedBBB] = useState([])
    const [ownedGB, setOwnedGB] = useState([])

    const handleError = (err) => {
        toast({
            title: "Error",
            description: err.reason || err.message,
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            await provider.request({
                method: "wallet_requestPermissions",
                params: [{
                    eth_accounts: {}
                }]
            }).then(() => provider.request({
                method: 'eth_requestAccounts'
            }))
            setAddress(await signer.getAddress())
        } catch (err) {
            console.log(err)
        }
        try {
            await provider.enable()
            setAddress(await signer.getAddress())
        } catch (err) {
            console.log(err)
        }
    }, [provider, signer])

    const getStakedBBB = useCallback(async () => {
        const stakedBBB = await contract.getAllStakedBBB({ from: address })
        return stakedBBB.map(e => e.toString())
    }, [address, contract])

    const getStakedGB = useCallback(async () => {
        const stakedGB = await contract.getAllStakedGB({ from: address })
        return stakedGB.map(e => e.toString())
    }, [address, contract])

    const getCurrentReward = useCallback(async () => {
        const reward = await contract.currentReward(address)
        return reward
    }, [address, contract])

    useEffect(() => {
        signer.getAddress().then((address) => {
            setAddress(address)
        })
        constants.changeNetwork(43114)
        getStakedBBB().then(e => setStakedBBB(e))
        getStakedGB().then(e => setStakedGB(e))
        getCurrentReward().then(e => setCurrentReward(e))
    }, [address, connect, getCurrentReward, getStakedBBB, getStakedGB, signer])

    useMemo(() => {
        signer.getAddress().then((address) => {
            if (!nfts?.length) {
                fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses%5B0%5D=0x84FaB74795F51C3208379924c76abE6e5A36D392&token_addresses%5B1%5D=0x03eD65272eb935F3937e1275deAa63bc3ecd1C27", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nft) => {
                    nft.json().then(e => {
                        setNfts(e.result)
                    })

                })
            }
        })
        if (!ownedBBB.length && !ownedGB.length && nfts.length) {
            nfts?.forEach((nft) => {
                if (nft.token_address === "0x84FaB74795F51C3208379924c76abE6e5A36D392".toLowerCase()) {
                    setOwnedBBB(ownedBBB => [...ownedBBB, nft.token_id])
                } else if (nft.token_address === "0x03eD65272eb935F3937e1275deAa63bc3ecd1C27".toLowerCase()) {
                    setOwnedGB(ownedGB => [...ownedGB, nft.token_id])
                }
            })
        }
    }, [nfts, ownedBBB, ownedGB, signer])

    const stakeAllBBB = async () => {
        console.log(ownedBBB)
        try {
            const tx = await contract.stakeMultipleBBB(ownedBBB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Staked all BBB",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    const stakeAllGB = async () => {
        console.log(ownedGB)
        try {
            const tx = await contract.stakeMultipleGB(ownedGB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Staked all GB",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    const unstakeAllBBB = async () => {
        console.log(ownedBBB)
        try {
            const tx = await contract.unstakeMultipleBBB(ownedBBB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Unstaked all BBB",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    const unstakeAllGB = async () => {
        console.log(ownedGB)
        try {
            const tx = await contract.unstakeMultipleGB(ownedGB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Unstaked all GB",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    const claimBBBReward = async () => {
        console.log(ownedBBB)
        try {
            const tx = await contract.claimMultipleBBB(ownedBBB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Claimed all BBB rewards",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    const claimGBReward = async () => {
        console.log(ownedGB)
        try {
            const tx = await contract.claimMultipleGB(ownedGB)
            await tx.wait()
            toast({
                title: "Success",
                description: "Claimed all GB rewards",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            handleError(err)
        }
    }

    useColorMode().setColorMode("dark")

    return (

        <>
            <Container maxW="container.xl" mt="10">
                <Heading as="h1" size="2xl" mb="3">The Graveyard</Heading>
                <Button mb='3' onClick={connect}>{address ? shorten(address) : "Connect"}</Button>
                <Grid templateColumns="repeat(auto-fill, minmax(400px, 1fr))" gap={6}>
                    <GridItem>
                        <Box p="5" bg="blackAlpha.700" borderRadius="md">
                            <Heading as="h2" size="lg" mb="5">BadBizBones</Heading>
                            <Text>currentReward: {Number(ethers.utils.formatEther(currentReward?.bizreward || 0)).toFixed(2)} $BRUH2</Text>
                            <Button mb='2' onClick={claimBBBReward}>Claim Reward</Button>
                            <Grid templateColumns="repeat(auto-fill, minmax(100px, 1fr))" gap={6}>
                                {nfts?.filter(e => e.token_address === "0x84FaB74795F51C3208379924c76abE6e5A36D392".toLowerCase()).map((nft, i) => {
                                    const metadata = JSON.parse(nft.metadata)
                                    return (
                                        <GridItem key={i}>
                                            <Box key={i} p="5" bg="black" borderRadius="md" mb="5">
                                                <Image borderRadius={'md'} src={"https://pub-b13d985639f64fc8810c584b510cea1b.r2.dev/images/" + nft.token_id + ".jpg"} />
                                                <Text>{metadata?.name}</Text>
                                                {stakedBBB?.includes(nft.token_id) ? <Text>Staked</Text> : <Text>Not Staked</Text>}
                                            </Box>
                                        </GridItem>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <ButtonGroup mt="5" spacing="6">
                            <Button onClick={stakeAllBBB}>Stake All BBB</Button>
                            <Button onClick={unstakeAllBBB}>Unstake All BBB</Button>
                        </ButtonGroup>
                    </GridItem>
                    <GridItem>
                        <Box p="5" bg="blackAlpha.700" borderRadius="md">
                            <Heading as="h2" size="lg" mb="5">Giga Bones</Heading>
                            <Text>currentReward: {Number(ethers.utils.formatEther(currentReward?.gigareward || 0)).toFixed(2)} $BRUH2</Text>
                            <Button mb='2' onClick={claimGBReward}>Claim Reward</Button>
                            <Grid templateColumns="repeat(auto-fill, minmax(100px, 1fr))" gap={6}>
                                {nfts?.filter(e => e.token_address === "0x03eD65272eb935F3937e1275deAa63bc3ecd1C27".toLowerCase()).map((nft, i) => {
                                    const metadata = JSON.parse(nft.metadata)
                                    return (
                                        <GridItem key={i}>
                                            <Box key={i} p="5" bg="black" borderRadius="md" mb="5">
                                                <Image src={'https://giga-bones.herokuapp.com/images/' + nft.token_id + '.jpg'} />
                                                <Text>{metadata?.name}</Text>
                                                {stakedGB?.includes(nft.token_id) ? <Text>Staked</Text> : <Text>Not Staked</Text>}
                                            </Box>
                                        </GridItem>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <ButtonGroup mt="5" spacing="6">
                            <Button onClick={stakeAllGB}>Stake All GB</Button>
                            <Button onClick={unstakeAllGB}>Unstake All GB</Button>
                        </ButtonGroup>
                    </GridItem>
                </Grid>


            </Container>

        </>

    )

}